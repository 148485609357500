<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane :label="$t('yh.sjyzm')" name="first">
        <el-form :inline="true">
          <!-- 列表区域 -->
          <el-form-item>
            <el-input
              v-model="queryinfo.query"
              :clearable="true"
              @clear="getlist"
              style="width:240px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getlist"
              ></el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <el-table
          v-loading="loading"
          :data="loglist"
          border
          style="width: 100%;"
        >
          <el-table-column
            prop="area_code"
            :label="$t('yh.quhao')"
          ></el-table-column>
          <el-table-column
            prop="receive_phone"
            :label="$t('yh.jssjh')"
          ></el-table-column>
          <el-table-column
            prop="security_code"
            :label="$t('yh.yzm')"
          ></el-table-column>
          <el-table-column prop="is_failure" :label="$t('yh.sfsx')">
            <template slot-scope="{ row }">
              <span v-if="row.is_failure === '1'">{{ $t('shi') }}</span>
              <span v-else>{{ $t('fou') }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            :label="$t('cjsj')"
            :formatter="dateFormat"
          ></el-table-column>
          <el-table-column
            prop="failuretime"
            :label="$t('yh.sxsj')"
            :formatter="dateFormat"
          ></el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          :hide-on-single-page="total <= 10"
          background
          layout="prev, pager, next, total"
          :page-size="10"
          :total="total"
          :current-page="queryinfo.page"
          @current-change="changepage"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane :label="$t('yh.yxyzm')" name="second">
        <el-form :inline="true">
          <!-- 列表区域 -->
          <el-form-item>
            <el-input
              v-model="queryinfo.query"
              :clearable="true"
              @clear="getlist"
              style="width:240px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getlist"
              ></el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <el-table
          v-loading="loading"
          :data="loglist"
          border
          style="width: 100%;"
        >
          <el-table-column
            prop="send_email"
            :label="$t('yh.fsyx')"
          ></el-table-column>
          <el-table-column
            prop="receive_email"
            :label="$t('yh.jsyx')"
          ></el-table-column>
          <el-table-column
            prop="security_code"
            :label="$t('yh.yzm')"
          ></el-table-column>
          <el-table-column prop="is_failure" :label="$t('yh.sfsx')">
            <template slot-scope="{ row }">
              <span v-if="row.is_failure === '1'">{{ $t('shi') }}</span>
              <span v-else>{{ $t('fou') }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="createtime"
            :label="$t('cjsj')"
            :formatter="dateFormat"
          ></el-table-column>
          <el-table-column
            prop="failuretime"
            :label="$t('yh.sxsj')"
            :formatter="dateFormat"
          ></el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          :hide-on-single-page="total <= 10"
          background
          layout="prev, pager, next, total"
          :page-size="10"
          :total="total"
          :current-page="queryinfo.page"
          @current-change="changepage"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 获取用户列表的参数对象
      queryinfo: {
        query: '',
        page: 1,
        type: 'first'
      },
      loglist: [],
      total: 0,
      loading: true,
      activeName: 'first'
    }
  },
  created() {
    // 请求用户列表数据
    this.getlist()
  },
  methods: {
    // 获取用户列表
    async getlist() {
      this.loading = true
      const { data } = await this.$http.get('/admin/user/codelist', {
        params: this.queryinfo
      })
      if (data) {
        if (data.code !== 200) {
          this.$message.error(this.getlang(data.msg))
        } else {
          this.loglist = data.data.list
          this.total = parseInt(data.data.count)
        }
      }
      this.loading = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 格式化时间
    dateFormat(row) {
      const time = row.createtime
      const dt = new Date(parseInt(time) * 1000)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // 切换Tab
    handleClick(tab) {
      this.queryinfo.page = 1
      this.queryinfo.type = tab.name
      // console.log(tab.name)
      this.getlist()
    }
  }
}
</script>

<style lang="less">
.el-pagination {
  margin-top: 15px;
  text-align: center;
}
</style>
